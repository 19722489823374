// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-about-page-index-js": () => import("./../src/templates/about-page/index.js" /* webpackChunkName: "component---src-templates-about-page-index-js" */),
  "component---src-templates-blog-page-index-js": () => import("./../src/templates/blog-page/index.js" /* webpackChunkName: "component---src-templates-blog-page-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../src/templates/blog-post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-index-page-index-js": () => import("./../src/templates/index-page/index.js" /* webpackChunkName: "component---src-templates-index-page-index-js" */),
  "component---src-templates-project-index-js": () => import("./../src/templates/project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-resume-page-index-js": () => import("./../src/templates/resume-page/index.js" /* webpackChunkName: "component---src-templates-resume-page-index-js" */),
  "component---src-templates-tag-index-js": () => import("./../src/templates/tag/index.js" /* webpackChunkName: "component---src-templates-tag-index-js" */),
  "component---src-templates-work-page-index-js": () => import("./../src/templates/work-page/index.js" /* webpackChunkName: "component---src-templates-work-page-index-js" */)
}

